
<template lang="">
    <div>
        Page not found
    </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>